import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../global/Layout'
import ContactWrapper from '../layouts/ContactWrapper'
import SimpleSection from '../layouts/SimpleSection'
import Footer from '../layouts/Footer'
import {
  ContentWrapperStyled,
  SimpleNumberedListStyled,
  SimpleDottedListStyled,
  SimpleListItemStyled,
  CustomLinkStyled,
  CookieSettingsStyled,
  CookiePolicyStyled,
} from '../common/common'
import { Heading1, Heading2, Typo4 } from '../components/Typography'

const cookieLinkItems = [
  {
    href: '#privacy',
    to: 'privacy',
    label: 'Your Privacy',
  },
  {
    href: '#cookies',
    to: 'cookies',
    label: 'What Are Cookies',
  },
  {
    href: '#cookies-types',
    to: 'cookies-types',
    label: 'Types of Cookies',
  },
  {
    href: '#data',
    to: 'data',
    label: 'How AutoloadIT will handle your data',
  },
  {
    href: '#third-party-cookies',
    to: 'third-party-cookies',
    label: 'Third party cookies',
  },
  {
    href: '#retention-period',
    to: 'retention-period',
    label: 'Retention Period',
  },
  {
    href: '#rights',
    to: 'rights',
    label: 'Your Rights and Our Contact Information',
  },
]

const OPTANON_COOKIE_POLICY_CONTAINER_ID = 'optanon-cookie-policy'

const CookiePolicyPage = ({ data }) => {
  useEffect(() => {
    setTimeout(() => {
      const cookiePolicyContainer = document.querySelector(
        `#${OPTANON_COOKIE_POLICY_CONTAINER_ID}`,
      )

      if (
        window.Optanon &&
        cookiePolicyContainer &&
        !cookiePolicyContainer.textContent
      ) {
        window.Optanon.loadDefaultBannerGroup()
        window.Optanon.LoadBanner()
      }
    })
  }, [])

  return (
    <Layout>
      <ContactWrapper car={data.carThree.childImageSharp.fluid} />
      <SimpleSection>
        <ContentWrapperStyled>
          <Heading1 as="h1">Cookie Policy</Heading1>
          <CookieSettingsStyled>
            <a className="optanon-show-settings">Cookie Settings</a>
          </CookieSettingsStyled>
          <CookiePolicyStyled>
            <div id={OPTANON_COOKIE_POLICY_CONTAINER_ID} />
          </CookiePolicyStyled>
          <SimpleNumberedListStyled>
            {cookieLinkItems.map(({ href, to, label }) => (
              <SimpleListItemStyled key={href}>
                <CustomLinkStyled
                  href={href}
                  to={to}
                  spy
                  smooth
                  offset={-100}
                  duration={500}
                >
                  {label}
                </CustomLinkStyled>
              </SimpleListItemStyled>
            ))}
          </SimpleNumberedListStyled>
          <Heading2 as="h2" id="privacy">
            Your privacy
          </Heading2>
          <Typo4>
            Autoload IT aim to offer the best customer experience and we take
            the privacy and needs of our customers very seriously. We aim in
            this policy to outline how we use, store and handle your data on our
            website.
          </Typo4>
          <Typo4>
            When you visit our web site, we will use cookies to ensure that you
            get the best experience on our website. These cookies collect data
            that might be about you, your preferences or your device. This
            information could directly identify you. For example, the main
            purpose for collecting your IP address would be to personalise your
            experience but your IP address could be used for aggregated data
            reporting and analysis purposes. Because we respect your right to
            privacy, you can choose not to allow some types of cookies. Click on
            the different category headings in the AutoloadIT Privacy Preference
            Centre to find out more and change our default settings. Blocking
            some types of cookies may impact on your experience of the site and
            the services we are able to offer. Your cookie choices made on this
            site will be remembered whenever you visit autoloadit.com but you
            will be offered the opportunity to review our cookie privacy
            preference centre at the start of every visit to autoloadit.com
          </Typo4>
          <Typo4>
            We will always need to use a cookie to remember the choices that you
            have made within our privacy preference centre. This will have a
            couple of consequences:
          </Typo4>
          <SimpleDottedListStyled>
            <SimpleListItemStyled>
              <Typo4>
                If you delete all your cookies you will have to update your
                preferences with us again.
              </Typo4>
            </SimpleListItemStyled>
            <SimpleListItemStyled>
              <Typo4>
                If you use a different device or browser you will have to tell
                us your preferences again.
              </Typo4>
            </SimpleListItemStyled>
          </SimpleDottedListStyled>
          <Heading2 as="h2" id="cookies">
            What are cookies?
          </Heading2>
          <Typo4>
            &quot;Cookies&quot; are small text files that are stored on your
            computer or mobile device when you visit a website.
          </Typo4>
          <Typo4>
            They allow the website to recognise your device and remember if
            you&apos;ve been to the website before.
          </Typo4>
          <Typo4>
            Cookies are very common web technology which have been in use for
            years. Cookies are widely used in order to make websites work more
            efficiently, as well as providing information to the owners of
            websites including but not limited to your IP addresses.
          </Typo4>
          <Typo4>
            Cookies are used in many ways, for example to measure which parts of
            the website people visit and to customise your experience. Cookies
            also provide information that helps us monitor and improve the
            website&apos;s performance.
          </Typo4>
          <Typo4>
            You can read more about the different types of cookies that we use
            on this website below.
          </Typo4>
          <Typo4>
            To find out more about cookies, including how to see what cookies
            have been set on your computer and how to manage and delete them,
            visit{' '}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.allaboutcookies.org
            </a>
          </Typo4>
          <Heading2 as="h2" id="cookies-types">
            Types of cookies
          </Heading2>
          <Typo4>Strictly Necessary Cookies</Typo4>
          <Typo4>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
            These cookies do store personally identifiable information.
          </Typo4>
          <Typo4>Performance Cookies</Typo4>
          <Typo4>
            These cookies allow us to count visits and traffic sources so we can
            measure and improve the performance of our site. They help us to
            know which pages are the most and least popular and see how visitors
            move around the site. Information collected by these cookies is
            aggregated and anonymised. If you do not allow these cookies we will
            not know when you have visited our site or be able to monitor its
            performance and tailor your experience on future visits.
          </Typo4>
          <Typo4>Functional Cookies</Typo4>
          <Typo4>
            These cookies enable the website to provide enhanced functionality
            and personalisation. They may be set by us or by third party
            providers whose services we have added to our pages. If you do not
            allow these cookies then some or all of these services may not
            function properly.
          </Typo4>
          <Typo4>Targeting Cookies</Typo4>
          <Typo4>
            These cookies may be set through our site by our advertising
            partners, whose details can be found in our privacy preference
            centre under the More Info tab. They may be used by those companies
            to build a profile of your interests and show you relevant adverts
            on other sites. These cookies store data that uniquely identify your
            browser and internet device but not other personally identifying
            information. If you do not allow these cookies, you will experience
            less targeted advertising.
          </Typo4>
          <Heading2 as="h2" id="data">
            How AutoloadIT will handle your data
          </Heading2>
          <Typo4>
            We use cookies to enhance the performance of our website and
            personalise your online AutoloadIT experience and to do so we will
            store your preferences and IP address or other device identifiers.
          </Typo4>
          <Typo4>
            Cookies help us to collect information on how people use our website
            and which pages they visit. They enable us to monitor the number of
            visitors and to analyse website usage patterns and trends.
          </Typo4>
          <Typo4>
            We aim to collect this information anonymously, so it doesn&apos;t
            identify anyone as an individual and no personal information (other
            than IP addresses and similar device identifiers) is stored in our
            cookies. We always use cookie data in a responsible way. We store IP
            addresses and device identifiers to enable us to offer you a better
            online experience. These data will be shared with our website
            provider with whom we have a data sharing agreement to ensure they
            have the correct infrastructure in place to appropriately and
            correctly handle and store this data. Third party cookies may come
            from partners that provide functional web tools for our site, other
            websites where we advertise and from social networks. Information on
            these cookies can be found in our privacy preference centre under
            the More Info tab.
          </Typo4>
          <Typo4>
            Our aim is to continually improve the AutoloadIT online experience.
            Cookies help to make our website work better and more efficiently.
            However, if you want to find out more on how to disable cookies you
            can do so here:{' '}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.allaboutcookies.org
            </a>
            .
          </Typo4>
          <Typo4 textTransform="uppercase">
            AutoloadIT processes your personal information on the following
            grounds;
          </Typo4>
          <Typo4 textTransform="uppercase">Performance of a contract</Typo4>
          <Typo4>
            AutoloadIT provides a service where customers can create a personal
            account and login. This allows a customer to personalise and set
            preferences, an example being to have favourite models of interest.
            We will process the personal data collected in order to fulfil the
            needs to each individual customer.
          </Typo4>
          <Typo4 textTransform="uppercase">Consent</Typo4>
          <Typo4>
            When you visit autoloadit.com we offer the opportunity for you to
            consent to the use of our cookies. In the main our customers are
            happy to consent to do so by clicking the ‘accept cookies’ box on
            our cookie banner. Our cookie banner will pop up on every visit to
            allow visitors to our website to change their mind and update their
            cookie preferences at any time. On any visit, you can click on the
            ‘cookie settings’ box which will take your directly to the
            AutoloadIT and allow you to change your preferences. Where the legal
            basis for us using your IP address or other personal identifiable
            information is consent, we shall always offer the option for you to
            switch cookies off. Should you take this option and disable cookies,
            only cookies described in the section Strictly Necessary Cookies
            above will remain active to ensure our website functions properly.
          </Typo4>
          <Typo4>
            When you visit our website and consent to the use of our cookies, we
            shall collect and process your IP address and other personally
            identifiable information to enable us to identify return visitors to
            our website, to analyse the behaviour of visitors to our website to
            enhance our communication and the structure of our website and to
            build a profile of visitors’ interests to be able to show relevant
            adverts for our products and services. You have the right to
            withdraw your consent at any time and decline the use of these
            cookies within the Privacy Preference Centre. Should you be unsure
            on how to do this please direct a request for withdrawal of consent
            to the data protection team at AutoloadIT on{' '}
            <a href="mailto:info@autoloadit.com">info@autoloadit.com</a>.
          </Typo4>
          <Heading2 as="h2" id="third-party-cookies">
            Third party cookies
          </Heading2>
          <Typo4>
            When you visit autoloadit.com you may notice embedded third party
            content that is not related to AutoloadIT, for example, content from
            YouTube. Cookies from these websites may be stored on your device.
            Details of all third party affiliates who may set cookies on your
            device can be found in our privacy preference centre under the More
            Info tab. We do not control the settings of these cookies, so please
            check relevant third party websites for more information about their
            cookies and how to manage them.
          </Typo4>
          <Typo4>
            <strong>
              Examples of when AutoloadIT may use third party cookies:
            </strong>
          </Typo4>
          <Typo4>
            <strong>Functional web tools: </strong>We offer some useful tools on
            autoloadit.com which require cookies. Examples of these could
            include, remembering you when you login to make it easier to
            remember your preferences. We can also track a favourite car if you
            wish to do so.
          </Typo4>
          <Typo4>
            <strong>AutoloadIT advertising: </strong>We may advertise on other
            websites and set cookies on your devices to ensure the advert is
            relevant to your preferences. This use of cookies would allow us to
            display personalised adverts that are relevant to you. You can find
            more information about targeting cookies in the section 3 of this
            policy.
          </Typo4>
          <Typo4>
            If you want to find out more on cookies please visit{' '}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.allaboutcookies.org
            </a>
          </Typo4>
          <Heading2 as="h2" id="retention-period">
            Retention time
          </Heading2>
          <Typo4>
            We may retain your personal data up to two (2) years after your last
            visit to our website. In the case of a customer who holds an online
            account with us this would be up to 2 years after you deactivate
            your account with us. For a website visitor without an account we
            would hold this data for no longer than two years after you last
            visited our website.
          </Typo4>
          <Heading2 as="h2" id="rights">
            Your Rights and Our Contact Information
          </Heading2>
          <Typo4>
            For more information on your rights in relation to our processing of
            your personal data and contact details for further information and
            complaints as well as contact details to our Data Protection Team at{' '}
            <a href="mailto:info@autoloadit.com">info@autoloadit.com</a> or
          </Typo4>
          <Typo4>
            AutoLoad IT,
            <br />
            Administration Centre,
            <br />
            664 Victoria Road,
            <br />
            South Ruislip,
            <br />
            Middlesex, United Kingdom,
            <br />
            HA4 0LN
          </Typo4>
        </ContentWrapperStyled>
      </SimpleSection>
      <Footer />
    </Layout>
  )
}

export default CookiePolicyPage

export const query = graphql`
  query {
    carThree: file(relativePath: { eq: "img-car-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
